import dayjs from "dayjs";
import CodiceFiscale from "codice-fiscale-js";
import { req } from "~/validator/commons";

function isAdultAge(error) {
  var errorMessage = error
    ? error
    : "E' necessario essere maggiorenni per procedere.";
  return function(v) {
    if (v) {
      if (!dayjs(v).isValid()) {
        v = dayjs(v, "DD/MM/YYYY").format("YYYY-MM-DD");
      }
      return dayjs().diff(dayjs(v), "year", true) >= 18 || errorMessage;
    }
    return true;
  };
}

function isEmailOrValidCf(error) {
  var errorMessage =
    error ||
    "Digitare una e-mail o il codice fiscale valido di persona maggiorenne";
  return function(v) {
    if (v) {
      let isCf = CodiceFiscale.check(v.toUpperCase());
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,11}$/;
      let isEmail = emailRegex.test(v);
      if (isCf) {
        return (
          dayjs().diff(dayjs(new CodiceFiscale(v).birthday), "year", true) >=
            18 || errorMessage
        );
      } else {
        return !req(v) || isEmail || errorMessage;
      }
    }
    return true;
  };
}

function isValidCf(error) {
  var errorMessage = error
    ? error
    : "Il C.F. inserito non è valido o è di persona minorenne";
  return function(v) {
    if (v) {
      let isValid = CodiceFiscale.check(v.toUpperCase());
      if (isValid) {
        return (
          !req(v) ||
          dayjs().diff(dayjs(new CodiceFiscale(v).birthday), "year", true) >=
            18 ||
          errorMessage
        );
      } else {
        return errorMessage;
      }
    }
    return true;
  };
}

function isValidCountryItem(error) {
  var errorMessage = error ? error : "Seleziona la tua nazione dall'elenco";
  return function(v) {
    return !req(v) || (typeof v === "object" && v.itemId > 0) || errorMessage;
  };
}

export { isAdultAge, isEmailOrValidCf, isValidCf, isValidCountryItem };
